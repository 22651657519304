// count down
// Set the date we're counting down to

let dateElem = document.getElementById('openingCountdown')
let dataopening = dateElem.getAttribute("data-opening")
let daysElem = dateElem.querySelector(".days")
let hoursElem = dateElem.querySelector(".hours")
let minsElem = dateElem.querySelector(".minutes")
let secondsElem = dateElem.querySelector(".seconds")

let countDownDate = new Date(dataopening).getTime();

// Update the count down every 1 second
let x = setInterval(function() {

    // Get today's date and time
    var now = new Date().getTime();
    
    // Find the distance between now and the count down date
    var distance = countDownDate - now;
    
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    
    daysElem.innerHTML = days +""
    hoursElem.innerHTML = hours +""
    minsElem.innerHTML = minutes +""
    secondsElem.innerHTML = seconds +""


    // Output the result in an element with id="fol-starts"
    // document.getElementById("fol-starts").innerHTML = "<div class='text-center'><div class='inline-block countdown items-center bg-transparent text-fol-green lg:text-5xl md:text-4xl sm:text-2xl m-3 p-5'>" + 
    // days + "<br></br><p class='block uppercase count-text text-lg pt-3'>Days</p></div>" + "<div class='inline-block countdown items-center bg-transparent rounded-lg text-gray-800 lg:text-5xl md:text-4xl sm:text-2xl m-3 p-5'>" + 
    // hours + "<br></br><p class='uppercase count-text text-lg pt-3'>Hours</p></div>" + "<div class='inline-block countdown items-center bg-transparent rounded-lg text-fol-green lg:text-5xl md:text-4xl sm:text-2xl m-3 p-5'>" + 
    // minutes + "<br></br><p class='uppercase count-text text-lg pt-3'>Minutes</p></div>" + "<div class='inline-block countdown items-center bg-transparent rounded-lg text-gray-800 lg:text-5xl md:text-4xl sm:text-2xl m-3 p-5'>" + 
    // seconds + "<br></br><p class='uppercase count-text lg:text-lg md:text-sm pt-3'>Seconds</p></div></div>";
    
    // If the count down is over, write some text 
    if (distance < 0) {
        clearInterval(x);
        document.getElementById("demo").innerHTML = "EXPIRED";
    }
}, 1000);
  

// alert / notification block